import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import axios from "axios";
import "boxicons"; // Make sure boxicons are installed and imported
import "./OfferCard.css"; // Styles are defined here
import { toast } from "react-toastify";

const OfferCard = ({ imageUrl, offerText, linkUrl, offerId }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false); // State for modal visibility

  // Hover effects for the card
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  // Handle modal open/close
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // Function to call the delete API
  const handleDelete = async () => {
    try {
      await axios.delete(`https://server.atf-labs.com:3000/offers/${offerId}`);
      toast.success("Offer deleted successfully!");
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Delay reload by 2 seconds
      // Add any further actions here, like refreshing the offer list
    } catch (error) {
      alert("Error deleting offer: " + error.message);
    }
  };

  const handleCopyClick = (event) => {
    event.stopPropagation(); // Prevents the redirection when clicking the text
    navigator.clipboard.writeText(offerText);
    toast.success("Offer code copied!");
  };

  const handleCardClick = () => {
    window.location.href = `${linkUrl + "/" + offerId}`; // Redirects to the custom link
  };

  useEffect(() => {
    console.log(imageUrl);
  }, []);

  return (
    <>
      <div
        className="offer-card"
        style={{ backgroundImage: `url(${imageUrl})` }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleCardClick}>
        {/* Cross Icon for deleting offer */}
        <box-icon
          name="x"
          type="regular"
          color="red"
          title="Delete offer"
          className="delete-icon"
          onClick={handleOpen}></box-icon>

        <div
          className={`hover-text ${isHovered ? "show" : ""}`}
          onClick={handleCopyClick}>
          <p>Click to copy offer: {offerText}</p>
        </div>
      </div>

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description">
        <Box sx={modalStyle}>
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2">
            Confirm Delete
          </Typography>
          <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this offer? This action cannot be
            undone.
          </Typography>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

// Modal styling
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default OfferCard;
