import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOfferById, updateOffer } from "../../../API/OfferApi"; // Importing the API
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import QuillEditor from "../../Elements/RichEditor/QuillEditor";

const EditOffers = () => {
  const [offerId, setStateId] = useState(""); // Get the id from the route
  const [termsConditions, setTermsConditions] = useState({
    termsConditions: "",
  });
  const [state, setState] = useState({
    name: "",
    desc: "",
    discount: "",
    offerStart: null,
    offerEnd: null,
    termsConditions: "",
    imgUrl: "",
    shopId: "",
    shopName: "",
  });
  const [img, setImg] = useState({ preview: "", raw: null });
  const dispatch = useDispatch();
  // Fetch offer data by ID and set state
  const fetchOfferData = async (id) => {
    try {
      const response = await getOfferById(id);
      const offerData = response.data;
      setState(offerData);
      if (offerData.imgUrl) {
        setImg({ preview: offerData.imgUrl, raw: null }); // Set preview of the existing image
      }
    } catch (error) {
      console.error("Error fetching offer data:", error);
    }
  };

  // Handle image change and preview
  const onImageChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name.replace(/\s+/g, ""); // Remove white spaces

    // Create a new File object with the cleaned file name
    const cleanedFile = new File([file], fileName, { type: file.type });

    setImg({
      preview: URL.createObjectURL(cleanedFile),
      raw: cleanedFile,
    });
  };

  // Handle input change and update the state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevOffer) => ({ ...prevOffer, [name]: value }));
  };

  // Handle date changes
  const handleDateChange = (date, field) => {
    if (field === "offerStart" && date) {
      const newEndDate = new Date(date.getTime() + 24 * 60 * 60 * 1000);
      setState((prevOffer) => ({
        ...prevOffer,
        offerStart: date.getTime(),
        offerEnd: newEndDate.getTime(),
      }));
    } else {
      setState((prevOffer) => ({
        ...prevOffer,
        [field]: date ? date.getTime() : null,
      }));
    }
  };

  useEffect(() => {
    const hash = window.location.hash; // Get the full URL hash
    const id = hash.split("/").pop(); // Extract the ID from the URL
    setStateId(id);
    if (id) fetchOfferData(id);
  }, []);

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  // Submit updated offer data
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Updated offer data:", e);
    handleLoading(true);

    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("discount", state.discount);
    formData.append("shopId", state.shopId._id);
    formData.append("termsConditions", state.termsConditions);
    formData.append("offerStart", state.offerStart);
    formData.append("offerEnd", state.offerEnd);
    if (img.raw) {
      formData.append("img", img.raw); // Append new image only if selected
    }

    try {
      if (img) {
        const response = await updateOffer(offerId, formData);
        handleLoading(false);

        if (response.status === true) {
          toast.success(`${response.message}`);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000); // Delay reload by 2 seconds
        } else {
          toast.error(`${response.message}`);
        }
      } else {
        toast.warning("Please select an image to update the offer.");
      }
    } catch (error) {
      handleLoading(false);
      toast.error("Error updating offer: " + error.message);
    }
  };

  useEffect(() => {
    console.log(state.desc);
  }, [state.desc]);
  return (
    <form onSubmit={onSubmit} style={formStyle}>
      <h1 style={{ textAlign: "center", marginBottom: "60px" }}>Edit Offer</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="name"
            label="Offer Name"
            value={state.name}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="discount"
            type="text"
            label="Discount"
            value={state.discount}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="shopId"
            label="Shop ID"
            value={state.shopId._id}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DatePicker
            selected={state.offerStart ? new Date(state.offerStart) : null}
            onChange={(date) => handleDateChange(date, "offerStart")}
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            customInput={<TextField fullWidth label="Offer Start" />}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            selected={state.offerEnd ? new Date(state.offerEnd) : null}
            onChange={(date) => handleDateChange(date, "offerEnd")}
            showTimeSelect
            dateFormat="Pp"
            minDate={
              state.offerStart
                ? new Date(state.offerStart + 24 * 60 * 60 * 1000)
                : new Date()
            }
            customInput={<TextField fullWidth label="Offer End" />}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <QuillEditor
            value={state}
            onStateChange={setState}
            heading="About the Offer"
            title="About the Offer"
            htmlKey="termsConditions"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            Update Image
          </div>
          <input type="file" accept="image/*" onChange={onImageChange} />
          {img.preview ? (
            <img src={img.preview} width="280px" height="160px" alt="Preview" />
          ) : (
            <img
              src={state.imgURL}
              width="280px"
              height="160px"
              alt="Preview"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Update Offer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const formStyle = {
  margin: "20px auto",
  maxWidth: "700px",
};

export default EditOffers;
